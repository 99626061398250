<template>
  <div class="col-lg-4 col-md-6 mb-30">
    <div class="card">
      <div class="card-top">
        <img
          class="card-img-top"
          :src="`${getUploadsUrl}/${team.image}`"
          alt="Team member"
        />
        <!-- not display icons  -->
        <div class="card-icon">
          <a :href="team.facebook"><i class="fab fa-facebook-f"></i></a>
          <a :href="team.twitter"><i class="fab fa-twitter"></i></a>
          <a :href="team.linkedin"><i class="fab fa-dribbble"></i></a>
          <a :href="team.instagram"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
      <div class="card-body">
        <h5 class="title">{{ team.title_az }}</h5>
        <p class="text">{{ team.subtitle_az }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import sharedMethods from "../mixins/sharedMethods";
export default {
  props: {
    team: {
      require: true,
      type: Object,
    },
  },
  mixins: [sharedMethods]
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.team {
  .card {
    @include card-shadow;

    &-top {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: $gradient;
        @include transition;
        opacity: 0;
      }
    }

    &-icon {
      display: none;
      background-color: $white;
      font-size: 1.5rem;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      @include transform(translateX(-50%));
      //Media Query
      @include large {
        font-size: 1.2rem;
      }
      @include medium {
        font-size: 1.3rem;
      }

      i {
        color: #6c6c6c;
        padding: 0.5rem;
        @include transition();

        //Media Query
        @include large {
          padding: 0.4rem;
        }
        @include medium {
          padding: 0.3rem;
        }
        @include xsmall {
          font-size: 1rem;
        }

        &:hover {
          color: $secondary-color;
          background-color: darken($bg-grey, 6);
          @include transition();
        }
      }
    }

    &-body {
      text-align: center;
    }

    &:hover {
      .card-top::before {
        opacity: 0.8;
      }

      .card-icon {
        display: block;
        // z-index: 1;
        @include animation(fromBottom 1s forwards);
      }
      @include keyframes(fromBottom) {
        from {
          @include transform(translate(-50%, 30%));
        }
        to {
          @include transform(translate(-50%, -50%));
        }
      }

      .title {
        color: $secondary-color;
      }
    }
  }
}
</style>