<template>
  <div class="col-lg-4 col-md-6 stretch-col mb-30">
    <div class="card">
      <div class="card-header text">
        <p>Blog</p>
        <p>{{ new Date(lastblog.create_time*1000) | formatDate }}</p>
        <p class="card--comment">{{ lastblog.view_count }}</p>
        <!-- <p class="card--heart">50</p> -->
      </div>
      <router-link :to="{ name: 'PageBlogDetail', params: { id: lastblog.id } }"
        ><img
          class="card-img-top"
          :src="`${getUploadsUrl}/${lastblog.image}`"
          :alt="lastblog.title_az"
      /></router-link>
      <div class="card-body">
        <router-link
          :to="{ name: 'PageBlogDetail', params: { id: lastblog.id } }"
        >
          <h5 class="title">{{ lastblog.title_az }}</h5>
        </router-link>
        <p class="text">
          {{lastblog.short_text_az}}
        </p>
        <router-link
          :to="{ name: 'PageBlogDetail', params: { id: lastblog.id } }"
          class="btn"
          >Ardını oxu</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
  import sharedMethods from "../mixins/sharedMethods";
export default {
  props: {
    lastblog: {
      required: true,
      type: Object,
    },
  },
  mixins: [sharedMethods]
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/blogs.scss";
</style>