<template>
  <button @click="emitFilterCategoryHome" class="btn portfolio-menu">
    {{ category }}
  </button>
</template>

<script>

import sharedMethods from "../mixins/sharedMethods";

export default {
  props: {
    category: {
      type: String,
      required: true,
    },
    dataIndex: {
      required: true,
      type: Number
    }
  },
  mixins: [sharedMethods],
  methods: {
    emitFilterCategoryHome() {
      this.$emit('emitFilterCategory')
    }
  },
  created() {
  }
};
</script>