<template>
  <footer class="footer sttn-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="isDataLoaded" class="footer-top">
            <img
              :src="`${getUploadsUrl}/${contacts.logo}?${Date.now()}`"
              :alt="getSiteName"
              class="banner-img"
            />
            <div class="footer-social">
              <a v-if="contacts.facebook && contacts.facebook !== ''" target="_blank" :href="contacts.facebook"><i class="fab fa-facebook-f"></i></a>
              <a v-if="contacts.twitter && contacts.twitter !== ''" target="_blank" :href="contacts.twitter"><i class="fab fa-twitter"></i></a>
              <a v-if="contacts.google_plus && contacts.google_plus !== ''" target="_blank" :href="contacts.google_plus"><i class="fab fa-google-plus-g"></i></a>
              <a v-if="contacts.instagram && contacts.instagram !== ''" target="_blank" :href="contacts.instagram"><i class="fab fa-instagram"></i></a>
              <a v-if="contacts.linkedin && contacts.linkedin !== ''" target="_blank" :href="contacts.linkedin"><i class="fab fa-linkedin"></i></a>
              <a v-if="contacts.youtube && contacts.youtube !== ''" target="_blank" :href="contacts.youtube"><i class="fab fa-youtube"></i></a>
              <a v-if="contacts.whatsapp && contacts.whatsapp !== ''" target="_blank" :href="contacts.whatsapp"><i class="fab fa-whatsapp"></i></a>
              <a v-if="contacts.vkontakte && contacts.vkontakte !== ''" target="_blank" :href="contacts.vkontakte"><i class="fab fa-vk"></i></a>
              <a v-if="contacts.vimeo && contacts.vimeo !== ''" target="_blank" :href="contacts.vimeo"><i class="fab fa-vimeo"></i></a>
              <a v-if="contacts.pinterest && contacts.pinterest !== ''" target="_blank" :href="contacts.pinterest"><i class="fab fa-pinterest"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1px line  -->
    <hr />

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer-menu">
            <div class="footer-menu__left">
              <p>Bütün hüquqlar qorunur | <a href="/"><strong>{{getSiteName}}</strong></a>, <strong>{{ Date.now() | formatDate('YYYY') }}</strong></p>
            </div>
            <div class="footer-menu__right">
              <a
                v-for="footer_menu in menusData.footer_menus"
                :key="footer_menu.id"
                :href="'/#'+footer_menu.url"
                ><span>{{ footer_menu.title_az }}</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import sharedMethods from "../mixins/sharedMethods";
  import {mapState} from "vuex"
export default {
  data() {
    return {
    };
  },
  mixins: [sharedMethods],
  computed: {
    ...mapState({
      menus: (state) => state.menus.items,
    }),
    menusData() {
      return this.menus.data || {};
    },
    contacts() {
      return this.menus.data.contacts;
    },
    menusLength() {
      return this.menus && Object.keys(this.menus).length;
    },
    isDataLoaded() {
      return this.menusLength;
    },
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.footer {
  background-image: url(../assets/img/main-bg.jpg);
  background-size: cover;
  color: $white;
  padding-bottom: 0;

  .banner-img {
    max-width: 300px;
    @include xsmall {
      max-width: 220px;
    }
  }

  &-top {
    text-align: center;

    .logo {
      padding: {
        left: 1rem;
        right: 1rem;
      }
    }

    .footer-social {
      padding: 3rem 0 5rem;
      // Media Query
      @include xsmall {
        padding: 2rem 0;
      }
      i {
        font-size: 1.5rem;
        padding: 0.5rem;
        margin: 0 0.5rem;
        border: 1px solid $white;
        color: $white;
        @include transition;
        &.fa-facebook-f {
          padding: 0.5rem 0.8rem;
          @include xsmall {
            padding: 0.3rem 0.6rem;
          }
        }
        //Media Query
        @include medium {
          font-size: 1.4rem;
        }
        @include xsmall {
          font-size: 1.2rem;
          padding: 0.3rem;
          margin: 0 0.3rem;
        }

        &:hover {
          color: $secondary-color;
          background-color: $white;
        }
      }
    }
  }

  &-menu {
    @include flex;
    justify-content: space-between;
    padding: 2.5rem 0;

    //Media Query
    @include medium {
      font-size: 0.9rem;
    }
    @include small {
      padding: 2rem 0;
      flex-direction: column;
      text-align: center;
    }
    @include xsmall {
      flex-direction: column;
      text-align: center;
      padding: 2rem 0;
    }

    &__right {
      @include flex;

      //Media Query
      @include small {
        order: -1;
        margin: 0 auto 1.5rem;
      }
      @include xsmall {
        order: -1;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      a {
        margin-left: 1.5rem;
        color: $white;
        @include xsmall {
          margin-bottom: 0.3rem;
          margin-left: 0;
        }

        &:hover {
          color: darken($color: $white, $amount: 9);
        }
      }
    }
  }
}
</style>