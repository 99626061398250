<template>
  <div id="app">
    <router-view />
    <FooterItem />
  </div>
</template>

<script>
import FooterItem from "./components/FooterItem.vue";

export default {
  name: "App",
  components: {
    FooterItem,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/utilities.scss";
@import "@/assets/styles/reset.scss";
@import "@/assets/styles/buttons.scss";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
</style>
