export default {
    computed: {
        getUploadsUrl() {
            return process.env.VUE_APP_IMAGES_URL
        },
        getSiteName() {
            return process.env.VUE_APP_SITE_NAME
        },
        isFormInvalid () {
            return this.$v.form.$invalid
        }
    },
    methods: {
        filterCategory(data, index) {
            this.filteredPortfolios = [];
            if (index <= 0 || index === undefined) {
                this.filteredPortfolios = data;
            } else {
                data.filter((portfolio) => {
                    if (index === portfolio.type) {
                        this.filteredPortfolios.push(portfolio);
                    }
                });
            }
        }
    }
}