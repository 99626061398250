import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    fetchReviews({ state, commit }) {
      return axios.get("/api/v1/reviews").then((res) => {
        const reviews = res.data;
        commit('setItems', { resource: 'reviews', items: reviews }, { root: true })
        return state.items
      });
    },
  },
}