<template>
  <div>
    <header class="header page-header">
      <HeaderNav />
      <!-- 1px line  -->
      <hr />
      <section class="slider">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="banner">
                <div class="banner-heading" v-if="blog">{{blog.title_az}}</div>
                <div class="banner-text">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <section class="blog-read sttn-section">
      <div class="container" v-if="blog">
        <div class="row">
          <div class="col-12">
            <img
              :src="`${getUploadsUrl}/${blog.image}`"
              :alt="blog.title_az"
              class="blog-read__img mx-auto d-block img-fluid"
            />
          </div>
        </div>

        <div class="card-header">
          <div class="card-header__content">
            <p>{{ new Date(blog.create_time*1000) | formatDate }}</p>
            <p class="card--comment">{{ blog.view_count }} baxış</p>
            <!-- <p class="card--heart">50</p> -->
          </div>
        </div>

        <div class="blog-read__heading">
          {{ blog.title_az }}
        </div>
        <div class="">
          <blockquote>
            <p>
              <i class="fas fa-quote-left"></i> <span v-html="htmlspecialchars_decode(blog.short_text_az)"></span>
            </p>
          </blockquote>
          <p v-html="htmlspecialchars_decode(blog.text_az)"></p>
        </div>

        <div class="heading d-none">Post a comment</div>
        <ContactItemForm class="d-none" />
      </div>
      <div class="container" v-else>
        <div class="row">
          <h3>Blog not found</h3>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactItemForm from "@/components/ContactItemForm";
import HeaderNav from "@/components/HeaderNav";
import sharedMethods from "../mixins/sharedMethods";
const htmlspecialchars_decode = require('locutus/php/strings/htmlspecialchars_decode')
export default {
  components: { ContactItemForm, HeaderNav },
  data() {
    return {
      metas:{}
    }
  },
  computed: {
    blog() {
      return this.$store.state.blogs.item.data;
    }
  },
  methods: {
    htmlspecialchars_decode(str, qs=2) {
      return htmlspecialchars_decode(str, qs)
    }
  },
  mixins: [sharedMethods],
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.metas.title,
      // all titles will be injected into this template
      titleTemplate: '%s | '+this.getSiteName,
      meta: [
        { name: 'description', content: this.metas.description},
        { name: 'keywords', content: this.metas.keywords},
        { property: 'og:title', content: this.metas.title},
        { property: 'og:site_name', content: this.getSiteName},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  async created() {
    await this.$store.dispatch("menus/fetchMenus");
    const blogId = this.$route.params.id;
    await this.$store.dispatch("blogs/fetchBlogById", blogId);
    this.metas = {
      'title': this.blog.title_az,
      'description': this.blog.meta_description,
      'keywords': this.blog.tags,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/header.scss";
.blogpage {
  .banner {
    &-heading {
      font-weight: 400;
    }
    &-text {
      font-weight: 500;
      @include xsmall {
        font-weight: 400;
      }
    }
  }
}

/* BLOG READ PAGE */

.blog-read {
  &__img {
    // background-image: url(../assets/img/blog-read.jpg);
    // @include bg-cover;
    height: 70vh;
    margin-bottom: 1.5rem;
    //Media Query
    @include small {
      height: 50vh;
    }
    @include xsmall {
      height: 40vh;
    }
  }

  .card-header {
    background-color: transparent;
    border-bottom: 0;
    padding: 0;

    &__content {
      @include flex;

      p {
        margin-right: 1rem;
        @include xsmall {
          margin-right: 0.8rem;
        }
      }
    }
  }

  &__heading {
    font: 2rem $title-font;
    padding: 3rem 0;
    @include xsmall {
      font-size: 1.8rem;
    }
  }

  blockquote {
    @include flex;
    margin: 2.5rem 2rem;
    padding: 2rem;
    line-height: 2rem;
    border: 1px solid transparent; //border must come before border-image
    @include border-image($gradient);
    border-image-slice: 1;

    //Media Query
    @include xsmall {
      margin: 2rem 0;
      padding: 1rem;
    }

    p {
      // font-family: $blockquote-font;
      font-style: italic;
    }

    i {
      font-size: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .heading {
    padding: 5rem 0;
    position: static;

    @include xsmall {
      padding: 3rem 0;
    }

    //disable triangle after heading
    &::before,
    &::after {
      position: static;
      border: 0;
    }
  }
}
</style>