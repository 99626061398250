import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    lastItems: []
  },
  actions: {
    fetchLastPortfolios({ state, commit }) {
      return axios.get("/api/v1/portfolios/last-portfolios").then((res) => {
        const lastPortfolios = res.data;
        commit('setLastItems', { resource: 'portfolios', lastItems: lastPortfolios }, { root: true })
        return state.lastItems
      });
    },
    fetchPortfolios({ state, commit }) {
      return axios.get("/api/v1/portfolios").then((res) => {
        const portfolios = res.data;
        commit('setItems', { resource: 'portfolios', items: portfolios }, { root: true })
        return state.items
      });
    },
    fetchPortfolioById({ commit }, portfolioId) {
      return axios.get(`/api/v1/portfolios/${portfolioId}/testportfolio`)
          .then((res) => {
            const portfolio = res.data;
            commit('setItem', { resource: 'portfolios', item: portfolio }, { root: true })
            return portfolio;
      }).catch(err => {
          return Promise.reject(err)
      });
    },
  },
}