<template>
  <form>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="form-group d-flex flex-column flex-md-row">
      <div class="input-top d-block w-100 mr-4">
        <input
          v-model="form.firstname"
          @blur="$v.form.firstname.$touch()"
          type="text"
          class="form-control"
          placeholder="Adınız"
          required
        />
        <div v-if="$v.form.firstname.$error">
          <div v-if="!$v.form.firstname.required" class="form-error">
            Ad qeyd olunmayıb
          </div>
        </div>
      </div>
      <div class="d-block w-100">
        <input
          v-model="form.lastname"
          @blur="$v.form.lastname.$touch()"
          type="text"
          class="form-control"
          placeholder="Soyadınız"
          required
        />
        <div v-if="$v.form.lastname.$error">
          <div v-if="!$v.form.lastname.required" class="form-error">
            Soyad qeyd olunmayıb
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input
              v-model="form.phone"
              @blur="$v.form.phone.$touch()"
              type="text"
              class="form-control"
              id="phone"
              aria-describedby="phoneHelp"
              placeholder="Əlaqə nömrəniz"
              required
      />
      <div v-if="$v.form.phone.$error">
        <div v-if="!$v.form.phone.required" class="form-error">
          Əlaqə nömrəniz qeyd olunmayıb
        </div>
      </div>
    </div>
    <div class="form-group">
      <input
        v-model="form.email"
        @blur="$v.form.email.$touch()"
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Email"
        required
      />
      <div v-if="$v.form.email.$error">
        <div v-if="!$v.form.email.required" class="form-error">
          Email qeyd olunmayıb
        </div>
        <div v-if="!$v.form.email.email" class="form-error">
          Email düzgün deyil
        </div>
      </div>
    </div>
    <div class="form-group">
      <textarea
        v-model="form.message"
        @blur="$v.form.message.$touch()"
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
        placeholder="Sifariş detallarınızı qeyd edin..."
        required
      ></textarea>
      <div v-if="$v.form.message.$error">
        <div v-if="!$v.form.message.required" class="form-error">
          Mesaj qeyd olunmayıb
        </div>
        <div v-if="!$v.form.message.minLength" class="form-error">
          Mesajınız minimum {{ $v.form.message.$params.minLength.min }} hərfdən ibarət olmalıdır
        </div>
      </div>
    </div>
    <button
      @click.prevent="contactSubmit()"
      class="btn main-btn-colored btn-block"
    >
      Göndər
    </button>
  </form>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import sharedMethods from "../mixins/sharedMethods";
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "ContactItemForm",
  components: {Loading},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      form: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        message: "",
      },
    };
  },
  mixins: [sharedMethods],
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      phone: { required },
      email: { required, email },
      message: { required, minLength: minLength(50) },
    },
  },
  created() {},
  methods: {
    contactSubmit() {
      this.isLoading = true;
      this.$v.form.$touch();
      if (this.isFormInvalid === true) {
        this.isLoading = false;
        return false;
      }

      this.$store
        .dispatch("auth/contactFormSubmit", this.form)
        .then((res) => {
          this.isLoading = false;
          this.$toastr.s(res.message)
        })
        .catch((err) => {
          this.isLoading = false;
          const errorsMessage = err.data.errors.join("\n");
          this.$toastr.e(errorsMessage)
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.input-top {
  @include small {
    padding: 2rem 0;
    margin-bottom: 1rem 0;
  }
  @include xsmall {
    padding: 2rem 0;
    margin-bottom: 1rem 0;
  }
}
.form-control {
  border: {
    top: none;
    left: none;
    right: none;
  }
  border-radius: 0;
  background-color: transparent;
  padding-left: 0;
  font-weight: 500;

  &:hover,
  &:focus {
    @include placeholder {
      color: $secondary-color;
    }
    border-bottom-color: $secondary-color;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-group {
  padding-bottom: 2rem;

  .form-error {
    font-size: 0.7rem;
    color: red;
    margin-top: 0.3rem;
  }
}
</style>