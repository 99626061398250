import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    fetchServices({ state, commit }) {
      return axios.get("/api/v1/services").then((res) => {
        const services = res.data;
        commit('setItems', { resource: 'services', items: services }, { root: true })
        return state.items
      });
    },
  },
}