<template>
  <div class="single-counter col-md-4">
    <h4><span class="counter">{{count}}</span></h4>
    <p class="title">{{title}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    count: {
      required: true,
      type: Number
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.single-counter {
  text-align: center;
  border: 1px solid $white;
  color: $white;
  padding: 3rem 0;

  h4 {
    font-size: 3rem;
    padding-bottom: 4rem;
    position: relative;

    span::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 15%;
      bottom: 25%;
      left: 50%;
      @include transform(translateX(-50%));
      background-color: $white;
    }
  }

  .title {
    @include medium {
      font-size: 1.6rem;
    }
  }
}
</style>