import Vue from 'vue'
import Router from 'vue-router'

import PageHome from '@/pages/PageHome'
import PageBlogDetail from '@/pages/PageBlogDetail'
import PageBlogAll from '@/pages/PageBlogAll'
import PagePortfoliosAll from '@/pages/PagePortfoliosAll'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'PageHome',
      component: PageHome
    },
    {
      path: '/blogs/:id/test',
      name: 'PageBlogDetail',
      component: PageBlogDetail
    },
    {
      path: '/blogs',
      name: 'PageBlogAll',
      component: PageBlogAll
    },
    {
      path: '/portfolios',
      name: 'PagePortfoliosAll',
      component: PagePortfoliosAll
    },
  ],
  mode: 'history'
})

export default router