<template>
  <div class="skill-area">
    <div class="skill-content">
      <div class="skill-content-name">{{skillName}}</div>
      <div class="skill-content-percentage">{{percentage}}%</div>
    </div>
    <div class="progress">
      <div
        class="progress-bar progress-bar--a"
        role="progressbar"
        :aria-valuenow="percentage"
        :style="'width: '+percentage+'%'"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skillName: {
      type: String,
      required: true
    },
    percentage: {
      required: true,
      type: Number
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.skill {
  &-area {
    flex-direction: column;
    text-transform: uppercase;

    .skill-content {
      @include flex;
      justify-content: space-between;
      padding-bottom: 1.7rem;
      font-weight: 500;

      //Media Query
      @include xsmall {
        font-size: 0.9rem;
      }
    }

    .progress {
      border: 1px solid;
      border-image-slice: 1;
      border-image-source: $gradient;
      height: 0.8rem;

      // Media Query
      @include xsmall {
        height: 0.5rem;
      }

      &-bar--a {
        width: 60%;
        background-color: #9656fd;
      }
      &-bar--b {
        width: 80%;
        background-color: #fd4efd;
      }
      &-bar--c {
        width: 50%;
        background-color: #ca3afd;
      }
    }

    &:nth-child(2) {
      padding: 1.5rem 0;
      //Media Query
      @include small {
        padding: 1.9rem 0;
      }
    }
  }
}
</style>