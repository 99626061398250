<template>
  <div class="col-lg-4 col-md-6 stretch-col mb-30">
    <div class="card">
      <span class="service-icon">
        <i :class="service.icon"></i>
      </span>
      <div class="card-body">
        <h5 class="title text-center">{{ service.title_az }}</h5>
        <p class="text">{{ service.text_az }}</p>
        <a :href="`tel:${contacts.mobile_tel}`" @click="gaCall()" class="btn main-btn-w">İndi zəng edin</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      required: true,
      type: Object,
    }
  },
  computed: {
    contacts() {
      return this.$store.state.menus.items.data.contacts
    }
  },
  methods: {
    gaCall() {
      this.$gtag.event('Call now', {
        'event_category' : 'generate_lead',
        'event_label' : ''
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.service {
  .card {
    @include flex;
    @include card-shadow;
    min-width: 100%;

    .service-icon {
      margin: 3rem auto;
      font-size: 3rem;
      height: 5rem;
      width: 5rem;
      background-color: $white;
      color: $secondary-color;
      border: 4px solid $white;
      @include box-shadow(0 0 5px #e4e4e4);
      @include flex-center;
      @include transition();

      // Media Query
      @include xsmall {
        font-size: 3rem;
        height: 4rem;
        width: 4rem;
      }
    }

    &-body {
      @include flex;
      align-items: center;
      flex-direction: column;

      .btn {
        margin-top: auto;
      }
    }

    .title {
      font-weight: 500;
    }

    .text {
      padding: 3rem 0;
      text-align: center;
    }

    .main-btn-w {
      max-width: 225px;
    }

    &:hover {
      .service-icon,
      .main-btn-w {
        background: $secondary-color;
        color: $white;
      }
      .title {
        color: $secondary-color;
      }
    }
  }
}
</style>