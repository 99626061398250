import axios from "../../services/axios";

export default {
    namespaced: true,
    state: {
        metas: {}
    },
    actions: {
        contactFormSubmit(context, formData) {
            return axios.post('/api/v1/send-email', formData)
                .then(res => {
                    return res.data
                }).catch(err => {
                    return Promise.reject(err.response)
                })
        },
        fetchMetas({state, commit}) {
            return axios.get("/api/v1/index").then((res) => {
                const metas = res.data.metas;
                commit('setMetas', metas)
                return state.metas
            });
        }
    },
    getters: {},
    mutations: {
        setMetas(state, metas) {
            state.metas = metas
        },
    }
}