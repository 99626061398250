import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue } from 'bootstrap-vue';
import router from './router';
import store from './store';
import vuelidate from 'vuelidate';
import moment from 'moment';
import VueScrollactive from 'vue-scrollactive';
import VueMeta from 'vue-meta'
import VueToastr from "vue-toastr";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
});

Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
  defaultTimeout: 3000,
  defaultProgressBar: true,
  defaultProgressBarValue: 3,
  defaultType: "error",
  defaultPosition: "toast-top-right",
  defaultCloseOnHover: false,
  defaultClassNames: ["animated", "zoomInUp"]
});

Vue.config.productionTip = false;

Vue.use(vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueScrollactive);
Vue.use(VueMeta, {
  // optional pluginOptions
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

// Vue.component();

Vue.filter('formatDate', function (value, formatTime = 'LL') {
  if (!value) return ''
  return moment(value).format(formatTime)
})

new Vue({
  router,
  store,
  vuelidate,
  render: h => h(App),
}).$mount('#app')
