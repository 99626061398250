<template>
  <div>
    <header class="header">
      <HeaderNav />
      <hr />
      <HeaderSlider
        v-if="menus && menus.data && menus.data.contacts"
        :contacts="menus.data.contacts"
      />
    </header>

    <section id="services" class="service sttn-section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">
              Təklif etdiyimiz <span>xidmətlər</span>
            </div>
            <div class="heading">İşimizin peşəkarıyıq</div>
          </div>
        </div>
        <div class="row">
          <ServiceItem
            v-for="service in services.rows"
            :key="service.id"
            :service="service"
          />
        </div>
      </div>
    </section>

    <AboutItem id="aboutus" v-if="menus && menus.data" :menus="menus" />

    <section class="skill sttn-section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">
              Nə üçün <span>{{ getSiteName }}</span> ?
            </div>
            <div class="heading">Üstünlüklərimiz</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-30">
            <SkillsItem :skillName="'Professionallıq'" :percentage="90" />
          </div>
          <div class="col-md-6 mb-30">
            <SkillsItem :skillName="'Keyfiyyət'" :percentage="90" />
          </div>
          <div class="col-md-6 mb-30">
            <SkillsItem :skillName="'Müasirlik'" :percentage="90" />
          </div>
          <div class="col-md-6 mb-30">
            <SkillsItem
              :skillName="'Son texnologiyalardan istifadə'"
              :percentage="100"
            />
          </div>
          <div class="col-md-6 mb-30">
            <SkillsItem
              :skillName="'Daima yenilik öyrənirik'"
              :percentage="100"
            />
          </div>
          <div class="col-md-6 mb-30">
            <SkillsItem
              :skillName="'Texniki tapşırıqlarda istiqamət veririk'"
              :percentage="100"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- TEAM SECTION START  -->
    <section id="ourteam" class="team sttn-section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">Bizim <span>kreativ</span> komandamız</div>
            <div class="heading">Siz də komandamıza qoşula bilərsiniz</div>
          </div>
        </div>
        <div class="row">
          <TeamItem v-for="team in teams.data" :key="team.id" :team="team" />
        </div>
      </div>
    </section>
    <!-- TEAM SECTION END  -->

    <section id="portfolios" class="portfolio sttn-section">
      <div class="container container-flex">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">Bizim gördüyümüz <span>işlər</span></div>
            <div class="heading">portfoliomuzla yaxından tanış olun</div>
          </div>
        </div>

        <div class="row mb-30">
          <div class="text mx-sm-auto">
            <button
              @click="filterCategory(lastPortfolios.data, 0)"
              class="btn portfolio-menu"
            >
              Hamısı
            </button>
            <PortfoliosItemCategory
              v-for="(category, index) in portfoliosCategories.data"
              :key="index"
              :category="category"
              :dataIndex="parseInt(index)"
              @emitFilterCategory="filterCategory(lastPortfolios.data, index)"
            />
          </div>
        </div>
        <transition-group class="row">
          <PortfoliosItem
            v-for="portfolio in filteredPortfolios"
            :key="portfolio.id"
            :portfolioItem="portfolio"
            @emitShowPortfolio="showPortfolio"
          />
        </transition-group>
        <router-link :to="{ name: 'PagePortfoliosAll' }" class="btn main-btn-w"
          >Bütün işlərimiz</router-link
        >
      </div>

      <b-modal id="modal-portfolio" centered hide-footer>
        <template #modal-title>
          {{ selectedPortfolio.title_az }},
          <a :href="selectedPortfolio.link">>>{{ selectedPortfolio.domain }}</a>
        </template>
        <img
          class="img-fluid mx-auto d-block"
          :src="`${getUploadsUrl}/${selectedPortfolio.image}`"
          :alt="selectedPortfolio.title_az"
        />
        <p class="my-4">{{ selectedPortfolio.text_az }}</p>
      </b-modal>
    </section>

    <section class="pricing sttn-section d-none">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">our best <span>prices</span></div>
            <div class="heading">services price plan</div>
          </div>
        </div>

        <div class="row">
          <PriceItem />
        </div>
      </div>
    </section>

    <section class="counter-section sttn-section">
      <div class="container">
        <div class="counter-area" id="counter">
          <div class="row d-flex justify-content-between">
            <!-- single counter -->
            <CounterItem :count="'20+'" :title="'Bitirdiyimiz İşlər'" />
            <CounterItem :count="2" :title="'Böyük layihə'" />
            <CounterItem :count="5" :title="'İllik Təcrübə'" />
          </div>
        </div>
      </div>
    </section>

    <!-- BLOG SECTION START  -->
    <section
      id="blog"
      class="blog sttn-section bg-grey"
      v-if="lastBlogs.rows && lastBlogs.data.length > 0"
    >
      <div class="container container-flex">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">Texnologiya <span>blogumuz</span></div>
            <div class="heading">Son bloqlar</div>
          </div>
        </div>
        <div class="row"></div>
        <BlogItem
          v-for="lastblog in lastBlogs.data"
          :key="lastblog.id"
          :lastblog="lastblog"
        />
        <router-link :to="{ name: 'PageBlogAll' }" class="btn main-btn-w"
          >Bütün bloqlar</router-link
        >
      </div>
    </section>
    <!-- BLOG SECTION END  -->

    <!-- TESTIMONIAL SECTION START  -->
    <section
      id="reviews"
      class="testimonial sttn-section"
      v-if="reviews.rows && reviews.rows.length > 0"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">Bizim <span>müştərilərimiz</span></div>
            <div class="heading">Onların bizim haqqımızda fikirləri</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="carousel-testimonial">
              <carousel
                :autoplay="false"
                :dots="true"
                :nav="false"
                :margin="30"
                :autoplayHoverPause="true"
                :responsive="{
                  0: { items: 1 },
                  576: { items: 2 },
                  768: { items: 2 },
                  992: { items: 3 },
                }"
              >
                <ReviewItem
                  v-for="review in reviews.rows"
                  :key="review.id"
                  :review="review"
                />
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TESTIMONIAL SECTION END  -->

    <!-- CONTACT SECTION -->
    <section id="contact" class="contact sttn-section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="subheading">Bizimlə <span>əlaqə</span></div>
            <div class="heading">Buradan sayt sifarişi də edə bilərsiniz</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-5">
            <ContactItemInfo
              v-if="menus && menus.data && menus.data.contacts"
              :contacts="menus.data.contacts"
            />
          </div>
          <div class="col-lg-8 col-md-7">
            <ContactItemForm />
          </div>
        </div>
      </div>
    </section>
    <!-- CONTACT SECTION END -->
  </div>
</template>

<script>
import HeaderSlider from "@/components/HeaderSlider";
import ServiceItem from "@/components/ServiceItem";
import AboutItem from "@/components/AboutItem";
import ContactItemInfo from "@/components/ContactItemInfo";
import ContactItemForm from "@/components/ContactItemForm";
import TeamItem from "@/components/TeamItem";
import BlogItem from "@/components/BlogItem";
import PortfoliosItem from "@/components/PortfoliosItem";
import PortfoliosItemCategory from "@/components/PortfoliosItemCategory";
import ReviewItem from "@/components/ReviewItem";
import PriceItem from "@/components/PriceItem";
import CounterItem from "@/components/CounterItem";
import SkillsItem from "@/components/SkillsItem";
import { mapActions, mapState } from "vuex";
import sharedMethods from "../mixins/sharedMethods";
import HeaderNav from "../components/HeaderNav";
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
    HeaderNav,
    HeaderSlider,
    ServiceItem,
    AboutItem,
    ContactItemInfo,
    ContactItemForm,
    TeamItem,
    BlogItem,
    PortfoliosItem,
    PortfoliosItemCategory,
    ReviewItem,
    PriceItem,
    CounterItem,
    SkillsItem,
  },
  data() {
    return {
      selectedCategory: "0",
      selectedPortfolio: {},
      filteredPortfolios: [],
      metas: {},
    };
  },
  mixins: [sharedMethods],
  computed: {
    ...mapState({
      services: (state) => state.services.items,
      menus: (state) => state.menus.items,
      teams: (state) => state.teams.items,
      lastBlogs: (state) => state.blogs.lastItems,
      lastPortfolios: (state) => state.portfolios.lastItems,
      portfoliosCategories: (state) => state.portfoliosCategories.items,
      reviews: (state) => state.reviews.items,
    }),
    menusLength() {
      return Object.keys(this.menus).length;
    },
    isDataLoaded() {
      return this.menusLength;
    },
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.metas.title,
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.getSiteName,
      meta: [
        { name: "description", content: this.metas.description },
        { name: "keywords", content: this.metas.keywords },
        { property: "og:title", content: this.metas.title },
        { property: "og:site_name", content: this.getSiteName },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  methods: {
    ...mapActions("services", ["fetchServices"]),
    ...mapActions("menus", ["fetchMenus"]),
    ...mapActions("teams", ["fetchTeams"]),
    ...mapActions("blogs", ["fetchLastBlogs"]),
    ...mapActions("portfolios", ["fetchLastPortfolios"]),
    ...mapActions("portfoliosCategories", ["fetchPortfoliosCategories"]),
    ...mapActions("reviews", ["fetchReviews"]),
    ...mapActions("auth", ["fetchMetas"]),

    showPortfolio(portfolio) {
      this.selectedPortfolio = portfolio;
      this.$bvModal.show("modal-portfolio");
    },

    track() {
      this.$gtag.pageview({
        page_path: "/",
      });
    },
  },
  async created() {
    this.fetchMenus();
    await this.fetchLastPortfolios();
    await this.fetchMetas();
    this.fetchServices();
    this.fetchTeams();
    this.fetchLastBlogs();
    this.fetchPortfoliosCategories();
    this.fetchReviews();
    this.filterCategory(this.lastPortfolios.data, 0);
    this.metas = this.$store.state.auth.metas;
    this.track();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/header.scss";

.portfolio-menu {
  font-weight: 500;
  &:hover,
  &:focus {
    color: $secondary-color;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }

  // Media Query
  @include small {
    font-size: 0.9rem;
    padding: 0.2rem 0.4rem;
  }
  @include xsmall {
    padding: 0.2rem 0.3rem;
  }
}

/* ***COUNTER SECTION *** */
.counter-section {
  background: $secondary-color url(../assets/img/main-bg.jpg) center
    center/cover no-repeat;
}

/* // enable scroll owl carousel on mobile */
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: auto;
  touch-action: auto;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 1rem;
}

.owl-theme .owl-dots .owl-dot {
  span {
    background-color: $white;
  }
  &.active span {
    background-color: $secondary-color;
  }
  span,
  &.active span {
    width: 15px;
    height: 15px;
    margin: 5px 7px;
    border: 2px solid $secondary-color;
    transition: opacity 0.2s ease;
    border-radius: 50%;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

//making button in center after giving parent element flex (container-flex class)
.main-btn-w {
  margin: 3rem auto 0;
}
</style>