<template>
  <div class="col-lg-4 col-md-4 stretch-col mb-30">
    <div class="card">
      <div class="card-body">
        <h5 class="title">basic plan</h5>
        <div class="card-price">29 <span>/ Month</span></div>
        <ul class="text">
          <li>1 TH Storage</li>
          <li>Unlimited members</li>
          <li>50 projects</li>
          <li>70GB bandwith</li>
          <li>1 TH Storage</li>
        </ul>
        <a href="#" class="btn main-btn-w">register today</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.pricing {
  .card {
    @include card-shadow;
    text-align: center;
    width: 100%;

    &-body {
      padding: 3rem 2rem;
      @include flex;
      // align-items: center;
      flex-direction: column;
      @include medium {
        padding: 1rem 0.75rem;
      }

      .main-btn-w {
        padding: 1rem 3rem;
        margin-top: auto; // make button always at the bottom after giving flex to parent class
        //remove button from flex to put in the center
        display: block;
        margin-left: auto;
        margin-right: auto;
        @include medium {
          padding: 0.7rem 1.3rem;
        }
      }
    }

    .title {
      position: relative;
      margin-bottom: 3.5rem;
      @include medium {
        font-size: 1.5rem;
      }
    }
    .title::after {
      position: absolute;
      content: "";
      bottom: -80%;
      left: 50%;
      @include transform(translateX(-50%));
      height: 1px;
      width: 3rem;
      background-color: #ccc;
    }

    &-price {
      color: $secondary-color;
      font-size: 3rem;
      font-weight: 400;
      width: 80%;
      display: inline-block;
      margin: auto;
      position: relative;
      // Media Query
      @include large {
        font-size: 2.5rem;
      }
      @include medium {
        font-size: 2.3rem;
        width: 100%;
      }
      @include small {
        width: 50%;
      }
      @include xsmall {
        font-size: 2.3rem;
        width: 50%;
      }
      @include cmq(0, 470px) {
        width: 50%;
      }
      @include cmq(0, 400px) {
        width: 70%;
      }
      span {
        font-size: 1rem;
        @include xsmall {
          font-size: 0.9rem;
        }
      }

      &::before {
        content: "$";
        font-size: 1.7rem;
        position: absolute;
        top: 0;
        left: 10%;
        //Media Query
        @include large {
          left: 0%;
        }
        @include medium {
          left: 15%;
          font-size: 1.5rem;
        }
        @include xsmall {
          font-size: 1.5rem;
        }
        @include cmq(768px, 880px) {
          left: 5%;
        }
        @include cmq(633px, 768px) {
          left: 15%;
        }
        @include cmq(0, 470px) {
          left: 0;
        }
      }

      &.three-digit-price {
        @include large {
          width: 100%;
        }
        @include cmq(0, 400px) {
          width: 75%;
        }
        &::before {
          left: 5%;
          @include medium {
            left: 10%;
          }
          @include cmq(768px, 880px) {
            left: 0%;
          }
          @include cmq(633px, 768px) {
            left: 10%;
          }
          @include cmq(0, 470px) {
            left: 0;
          }
        }
      }
    }

    &:hover {
      .title {
        color: $secondary-color;
      }
      .main-btn-w {
        background-color: $secondary-color;
        color: $white;
      }
    }
  }

  .text {
    padding: 3rem 0;
    font-style: 0.95rem;
    li {
      text-align: start;
      border-bottom: 1px solid darken($bg-grey, 7);
      padding: 1rem 0;

      &::before {
        content: "✓";
        padding-right: 1rem;
        font-weight: 900;
      }
    }

    &:nth-child(1) {
      padding-top: 0;
    }
  }
}
</style>