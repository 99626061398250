<template>
    <div class="portfolio-product col-lg-4 col-md-6 mb-30">
        <div class="img-content">
            <img
                    :src="`${getUploadsUrl}/${portfolioItem.thumb}?${Date.now()}`"
                    class="w-100 d-block portfolio-image"
            />
            <div class="portfolio-overlay">
                <b-button
                        @click="setSelectedPortfolio(portfolioItem)"
                        type="button"
                        class="btn text portfolio-overlay__text"
                >
                    {{portfolioItem.title_az}}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import sharedMethods from "../mixins/sharedMethods";

    export default {
        props: {
            portfolioItem: {
                required: true,
                type: Object
            }
        },
        data() {
            return {};
        },
        mixins: [sharedMethods],
        computed: {
            portfolio() {
                return this.$store.state.portfolios.item;
            },
        },
        created() {
            // const portfolioId = this.$attrs.portfolio.id;
            // this.$store.dispatch("portfolios/fetchPortfolioById", portfolioId);
        },

        methods: {
            selectedPortfolio(portfolio) {
                this.$emit("emitShowPortfolio", portfolio);
            },
            async setSelectedPortfolio(portfolio) {
                await this.$store.dispatch('portfolios/fetchPortfolioById', portfolio.id)
                    .then(res => {
                        this.selectedPortfolio(res.data)
                    })
                    .catch(() => {
                    })
                //console.log(this.selectedPortfolio)
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/mixins.scss";

    .portfolio {
        min-height: 100%;

        .img-content {
            position: relative;
            @include card-shadow;

            .portfolio-overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: $gradient;
                opacity: 0;
                @include transition;
                display: flex;

                &__text {
                    width: 100%;
                    background-color: transparent;
                    border-color: transparent;
                    margin: auto;
                    text-align: center;
                    color: $white;
                    opacity: 0;
                    @include transition;
                }
            }

            &:hover {
                .portfolio-overlay {
                    opacity: 0.8;
                }

                .portfolio-overlay__text {
                    opacity: 1;
                }
            }
        }
    }
</style>