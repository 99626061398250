import Vue from 'vue'
import Vuex from 'vuex'

import menus from './modules/menus'
import services from './modules/services'
import teams from './modules/teams'
import blogs from './modules/blogs'
import portfolios from './modules/portfolios'
import portfoliosCategories from './modules/portfoliosCategories'
import reviews from './modules/reviews'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    menus,
    services,
    teams,
    blogs,
    portfolios,
    portfoliosCategories,
    reviews,
    auth
  },
  mutations: {
    setItems(state, { resource, items }) {
      state[resource].items = items
    },
    setItem(state, { resource, item }) {
      state[resource].item = item
    },
    setLastItems(state, { resource, lastItems }) {
      state[resource].lastItems = lastItems
    },
    setMetas(state, metas) {
      state.metas = metas
    },
  }
})