<template>
  <section class="about sttn-section text-center">
    <div class="container">
      <div class="row">
        <!-- text-center is given to center the image  -->
        <div class="col-lg-5">
          <img
            src="../assets/img/mission.jpg"
            class="d-block w-100 mx-auto"
            alt="About Image"
          />
        </div>
        <div class="col-lg-6 text-lg-left">
          <div class="heading about-heading">Biz kimik</div>
          <p class="text" v-html="menus.data.foot_about">
          </p>
          <a href="#portfolios" class="btn main-btn-w">Gördüyümüz işlər</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    menus: {
      type: Object,
      required: true,
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.about {
  &-heading {
    padding: 0;
    @include medium {
      padding-top: 2rem;
    }
    @include small {
      padding-top: 2rem;
    }
    @include xsmall {
      padding-top: 2rem;
    }

    &::before {
      position: static;
      border-top: 0px solid transparent;
      border-bottom: 0px solid transparent;
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;
    }

    &::after {
      position: static;
    }
  }

  .text {
    padding: 1.5rem 0;
  }
}
</style>