<template>
  <div>
    <header class="header page-header">
      <HeaderNav />
      <!-- 1px line  -->
      <hr />
      <section class="slider">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="banner">
                <div class="banner-heading">Gördüyümüz işlər</div>
                <div class="banner-text">
                  Gördüyümüz işlərin və xidmətlərin siyahısı ilə tanış ola bilərsiniz
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <section class="portfolio sttn-section">
      <div class="container container-flex">
        <div class="row mb-30">
          <div class="text mx-sm-auto">
            <button @click="filterCategory(portfolios.data, index)" class="btn portfolio-menu">
              Hamısı
            </button>
            <PortfoliosItemCategory
                    v-for="(category, index) in portfoliosCategories.data"
                    :key="index"
                    :category="category"
                    :dataIndex="parseInt(index)"
                    @emitFilterCategory="filterCategory(portfolios.data, index)"
            />
          </div>
        </div>
        <transition-group class="row">
          <PortfoliosItem
                  v-for="portfolio in filteredPortfolios"
                  :key="portfolio.id"
                  :portfolioItem="portfolio"
                  @emitShowPortfolio="showPortfolio"
          />
        </transition-group>
        <b-modal id="modal-portfolio" centered hide-footer>
          <template #modal-title>
            {{selectedPortfolio.title_az}}, <a :href="selectedPortfolio.link">>>{{ selectedPortfolio.domain }}</a
          >
          </template>
          <img class="img-fluid img-thumbnail mx-auto d-block"
               :src="`${getUploadsUrl}/${selectedPortfolio.image}`"
               :alt="selectedPortfolio.title_az"
          />
          <p class="my-4">{{ selectedPortfolio.text_az }}</p>

        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderNav from "../components/HeaderNav";
import sharedMethods from "../mixins/sharedMethods";
import PortfoliosItemCategory from "@/components/PortfoliosItemCategory";
import PortfoliosItem from "@/components/PortfoliosItem";

export default {
  components: { HeaderNav, PortfoliosItemCategory, PortfoliosItem },
  data() {
    return {
      selectedPortfolio: {},
      filteredPortfolios: [],
      selectedCategory: "0",
      metas: {}
    };
  },
  computed: {
    menus() {
      return this.$store.state.menus.items;
    },
    portfolios() {
      return this.$store.state.portfolios.items;
    },
    portfoliosCategories() {
      return this.$store.state.portfoliosCategories.items;
    },
  },
  mixins: [sharedMethods],
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.metas.title,
      // all titles will be injected into this template
      titleTemplate: '%s | '+this.getSiteName,
      meta: [
        { name: 'description', content: this.metas.description},
        { name: 'keywords', content: this.metas.keywords},
        { property: 'og:title', content: this.metas.title},
        { property: 'og:site_name', content: this.getSiteName},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  methods: {
    showPortfolio(portfolio) {
      this.selectedPortfolio = portfolio;
      this.$bvModal.show('modal-portfolio')
    }
  },
  async created() {
    await this.$store.dispatch("menus/fetchMenus");
    await this.$store.dispatch("portfoliosCategories/fetchPortfoliosCategories");
    await this.$store.dispatch("portfolios/fetchPortfolios");
    this.filterCategory(this.portfolios.data, 0);
    this.metas = this.portfolios.metas;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/header.scss";

/** **** PORTFOLIO **** */
.portfolio {
  min-height: 100%;

  .portfolio-menu {
    font-weight: 500;
    &:hover,
    &:focus {
      color: $secondary-color;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }

    .img-content {
      position: relative;
      @include card-shadow;

      .portfolio-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $gradient;
        opacity: 0;
        @include transition;
        display: flex;

        &__text {
          width: 100%;
          background-color: transparent;
          border-color: transparent;
          margin: auto;
          text-align: center;
          color: $white;
          opacity: 0;
          @include transition;
        }
      }

      &:hover {
        .portfolio-overlay {
          opacity: 0.8;
        }
        .portfolio-overlay__text {
          opacity: 1;
        }
      }
    }
  }
  // Media Query
  @include small {
    font-size: 0.9rem;
    padding: 0.2rem 0.4rem;
  }
  @include xsmall {
    padding: 0.2rem 0.3rem;
  }
}
</style>