<template>
    <div class="container" v-if="menus && menus.data && menus.data.contacts">
        <scrollactive :click-to-scroll="clickToScroll" :offset="80" :duration="800" bezier-easing-value=".5,0,.35,1"
                      class="navbar navbar-expand-lg navbar-light">
            <router-link :to="{ name: 'PageHome' }" class="navbar-brand">
                <img
                        :src="`${getUploadsUrl}/${menus.data.contacts.logo}?${Date.now()}`"
                        alt="Logo"
                />
            </router-link>
            <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <HeaderNavLink
                            v-for="header_menu in menus.data.header_menus"
                            :key="header_menu.id"
                            :header_menu="header_menu"
                    />
                </ul>
            </div>
        </scrollactive>
    </div>
</template>
<script>
    import sharedMethods from "../mixins/sharedMethods";
    import HeaderNavLink from "./HeaderNavLink";

    export default {
        props: {},
        components: {HeaderNavLink},
        data() {
            return {
                clickToScroll: false
            }
        },
        mixins: [sharedMethods],
        computed: {
            menus() {
                return this.$store.state.menus.items
            }
        },
        async created() {
            await this.$store.dispatch('menus/fetchMenus');
            if (this.$route.name === 'PageHome') {
                this.clickToScroll = true;
            }

        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/mixins.scss";
    @import "@/assets/styles/header.scss";
</style>