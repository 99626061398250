<template>
  <div>
    <header class="header page-header">
      <HeaderNav />
      <!-- 1px line  -->
      <hr />
      <section class="slider">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="banner">
                <div class="banner-heading">Bloqlar</div>
                <div class="banner-text">
                  Siz IT xəbərlər, texnologiyalar və həllər haqqında maraqlı bloglar oxuya bilərsiniz
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <section class="blog sttn-section bg-grey">
      <div class="container">
        <div class="row">
          <div
            v-for="blog in blogs.data"
            :key="blog.id"
            class="col-lg-4 col-md-6 stretch-col mb-30"
          >
            <div class="card">
              <div class="card-header text">
                <p>BLOG</p>
                <p>{{ new Date(blog.create_time*1000) | formatDate }}</p>
                <p class="card--comment">{{ blog.view_count }}</p>
                <!-- <p class="card--heart">50</p> -->
              </div>
              <router-link
                :to="{ name: 'PageBlogDetail', params: { id: blog.id } }"
                ><img
                  class="card-img-top"
                  :src="`${getUploadsUrl}/${blog.image}`"
                  alt="Blog image"
              /></router-link>
              <div class="card-body">
                <router-link
                  :to="{ name: 'PageBlogDetail', params: { id: blog.id } }"
                >
                  <h5 class="title">{{ blog.title_az }}</h5>
                </router-link>
                <p class="text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
                  rem odit deserunt nesciunt ut pariatur molestiae.
                </p>
                <router-link
                  :to="{ name: 'PageBlogDetail', params: { id: blog.id } }"
                  class="btn"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
        </div>

        <button class="btn main-btn-w load-btn">load more</button>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import sharedMethods from "../mixins/sharedMethods";
export default {
  components: { HeaderNav },
  mixins: [sharedMethods],
  data() {
    return {
      metas:{}
    }
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.metas.title,
      // all titles will be injected into this template
      titleTemplate: '%s | '+this.getSiteName,
      meta: [
        { name: 'description', content: this.metas.description},
        { name: 'keywords', content: this.metas.keywords},
        { property: 'og:title', content: this.metas.title},
        { property: 'og:site_name', content: this.getSiteName},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  computed: {
    blogs() {
      return this.$store.state.blogs.items;
    }
  },
  async created() {
    await this.$store.dispatch("blogs/fetchBlogs");
    await this.$store.dispatch("menus/fetchMenus");
    this.metas = await this.blogs.metas;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/blogs.scss";
@import "@/assets/styles/header.scss";
</style>