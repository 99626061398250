import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    lastItems: [],
  },
  actions: {
    fetchLastBlogs({ state, commit }) {
      return axios.get(`/api/v1/blogs/last-blogs`).then(res => {
        const lastBlogs = res.data
        commit('setLastItems', { resource: 'blogs', lastItems: lastBlogs }, { root: true })
        return state.lastItems
      })
    },
    fetchBlogs({ state, commit }) {
      return axios.get('/api/v1/blogs').then(res => {
        const blogs = res.data
        commit('setItems', { resource: 'blogs', items: blogs }, { root: true })
        return state.items
      })
    },
    fetchBlogById({ state, commit }, blogId) {
      return axios.get(`/api/v1/blogs/${blogId}/test`).then(res => {
        const blog = res.data
        commit('setItem', { resource: 'blogs', item: blog }, { root: true })
        return state.item
      })
    },
  }
}