<template>
  <div>
    <div class="title">Əlaqə məlumatlarımız</div>
    <div class="contact-info text">
      Aşağıdakı vasitələrlə bizimlə əlaqə saxlaya bilərsiniz
      <div>
        <div class="contact-info__details">
          <i class="fas fa-map-marker-alt"></i>
          <p>Ünvan: {{ contacts.address_az }}</p>
        </div>
        <div class="contact-info__details" v-if="contacts.home_tel.length > 0">
          <i class="fas fa-home"></i>
          <p>Əlaqə nömrəsi: <a :href="`tel:${contacts.home_tel}`">{{ contacts.home_tel }}</a></p>
        </div>
        <div class="contact-info__details">
          <i class="fas fa-phone-alt"></i>
          <p>Mobil: <a :href="`tel:${contacts.mobile_tel}`">{{ contacts.mobile_tel }}</a></p>
        </div>
        <div class="contact-info__details">
          <i class="fas fa-phone-alt"></i>
          <p>Whatsapp: <a :href="`tel:${contacts.other_tel}`">{{ contacts.other_tel }}</a></p>
        </div>
        <div class="contact-info__details" v-if="contacts.fax.length > 0">
          <i class="fas fa-fax"></i>
          <p>Fax: {{ contacts.fax }}</p>
        </div>
        <div class="contact-info__details">
          <i class="fas fa-envelope"></i>
          <p>Email: {{ contacts.email }}</p>
        </div>
        <div class="contact-info__details">
          <i class="fas fa-globe-asia"></i>
          <p>İş günləri: {{ contacts.working_days_az }}</p>
        </div>
        <div class="contact-info__details">
          <i class="fas fa-globe-asia"></i>
          <p>Qeyri iş günləri: {{ contacts.weekend_days_az }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.contact {
  &-info {
    padding-top: 2rem;
    & > div {
      padding-top: 2rem;
    }

    &__details {
      @include flex;

      i {
        align-self: center;
        color: $secondary-color;
      }

      p {
        padding: 0.5rem 1rem;
      }
    }
  }
}
</style>