import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    fetchTeams({ state, commit }) {
      return axios.get('/api/v1/team').then(res => {
        const teams = res.data
        commit('setItems', { resource: 'teams', items: teams }, { root: true })
        return state.items
      })
    }

  },
}