<template>
  <li class="nav-item">
    <a class="nav-link scrollactive-item" :href="`/#${header_menu.url}`">
      {{ header_menu.title_az }}
    </a>
  </li>
</template>

<script>
// import $ from "jquery";
export default {
  props: {
    header_menu: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.nav-item {
  text-transform: uppercase;
  padding: 2.25rem 0.5rem;
  /**Media Query */
  @include medium {
    padding: 1rem 0;
  }
  @include small {
    padding: 0.9rem 0;
  }
  @include xsmall {
    padding: 0.7rem;
  }

  .nav-link {
    color: $white;
    font-weight: 500;
    @include transition;
    &:hover,
    &:focus {
      color: darken($white, 9);
    }
    @include cmq(992px, 1063px) {
      padding: 4px;
    }
  }
}
</style>