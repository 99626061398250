import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    fetchPortfoliosCategories({ state, commit }) {
      return axios.get('/api/v1/portfolios/categories').then(res => {
        const categories = res.data
        commit('setItems', { resource: 'portfoliosCategories', items: categories }, { root: true })
        return state.items
      })
    }
  }
}