import axios from "../../services/axios";

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    fetchMenus({ state, commit }) {
      return axios.get("/api/v1/menus").then((res) => {
        const menus = res.data;
        commit('setItems', { resource: 'menus', items: menus }, { root: true })
        return state.items
      });
    },
  },

}