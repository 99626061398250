<template>
  <!-- SLIDER SECTION START  -->
  <section class="slider">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="banner">
            <img
              :src="`${getUploadsUrl}/${contacts.logo}?${Date.now()}`"
              alt="Logo"
              class="banner-img"
            />
            <div class="banner-heading">Pul və müştəri qazandıran saytları biz hazırlayırıq</div>
            <h1 class="banner-text">
              Biz komandamız sizlərə müasir dizaynla, yüksək keyfiyyətli saytlar və xidmətlər hazırlayacaq. Bizim gördüyümüz işlər sizlərə müştəri və pul qazandıracaq
            </h1>
            <a class="btn main-btn banner-btn" @click="gaCall()" :href="`tel:${contacts.mobile_tel}`">İndi zəng edin</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import sharedMethods from "../mixins/sharedMethods";
export default {
  props: {
    contacts: {
      type: Object,
      required: true,
    },
  },
  mixins: [sharedMethods],
  methods: {
    gaCall() {
      this.$gtag.event('Call now', {
        'event_category' : 'generate_lead',
        'event_label' : ''
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.slider {
  color: $white;
  padding: 6.5rem 0;
  // Media Query
  @include xsmall {
    padding: 3.2rem 0;
  }
  .banner {
    text-align: center;

    .banner-img {
      max-width: 300px;
      @include xsmall {
        max-width: 220px;
      }
    }
    &-heading {
      font-size: 3rem;
      font-family: $title-font;
      font-weight: 600;
      text-transform: capitalize;
      padding-top: 3rem;
      letter-spacing: 0.4rem;
      // Media Query
      @include medium {
        font-size: 2.5rem;
      }
      @include small {
        font-size: 2.3rem;
        letter-spacing: 0.3rem;
      }
      @include xsmall {
        font-size: 1.8rem;
        letter-spacing: normal;
        padding-top: 2rem;
        width: 80%;
        margin: auto;
      }
    }

    &-text {
      width: 40vw;
      margin: auto;
      padding: 2.5rem;
      font-weight: 400;
      line-height: 1.5rem;
      @include large {
        width: 50vw;
      }
      @include medium {
        width: 80vw;
      }
      @include small {
        width: 90vw;
      }
      @include xsmall {
        width: 100%;
        padding: 1.5rem;
        font-size: 0.9rem;
      }
    }

    &-btn {
      color: $white;
      @include main-btn;
      @include transition;

      &:hover {
        color: $secondary-color;
        background-color: $white;
      }
    }
  }
}
</style>